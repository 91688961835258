.ar-common-tooltip {
  display: none;
  width: max-content;
  height: 14px;
  padding: 3.5px 13px;
  transition: 1s opacity ease-in, 0.05s left ease-in, 0.05s top ease-in;
  border-radius: 7px;
  background: $th-primary-color;
  font-size: 7px;
  line-height: 1;
  pointer-events: none;
}
.ar-edit-page__wrapper {
  &[draggable='false'] {
    img {
      &:hover {
        + .ar-common-tooltip {
          display: block;
        }
      }
    }
  }
}
