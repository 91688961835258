// sass-lint:disable no-misspelled-properties
.ar-common-pagination {
  $pagination: '.ar-common-pagination';
  display: flex;
  justify-content: center;
  z-index: 1;
  &__page {
    display: flex;
    align-items: center;
    margin-right: 18px;
    gap: 10px;
  }
  &__select-page {
    display: none;
    border-radius: 5px;
    background-color: color('pure-white');
    box-shadow: $th-select-box-shadow;
    select {
      width: 74px;
      padding: 5px 18px 5px 15px;
      border: 0;
      outline: none;
      background: url('../#{$pagination-dropdown}') no-repeat center center;
      background-color: transparent;
      background-position: 50px center;
      color: color('primary');
      appearance: none;
      &:focus {
        outline: none;
        background-color: transparent;
        color: color('primary');
        box-shadow: none;
      }
      option {
        color: $th-primary-dark;
      }
    }
  }
  &__title {
    display: none;
    margin: 0 15px 0 0;
    color: $th-primary-color-alt;
    font-family: $lota-grotesque-regular;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0 0 0 5px;
    gap: 2px;
    #{$pagination}__page-item {
      width: 28px;
      height: 28px;
      margin-right: 4px;
      list-style: none;
      #{$pagination}__page-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 3px 0 0;
        transition: border 0.3s ease-in-out;
        border: $border-2;
        border-radius: 100%;
        background: #ececec;
        color: color('primary');
        font-size: 12px;
        font-weight: $font-weight-semi-bold;
        text-decoration: none;
        &.active,
        &:hover {
          border-radius: 50%;
          background-color: color('primary');
          color: color('pure-white');
        }
        &[aria-label='Previous page'],
        &[aria-label='Next page'] {
          background: color('pure-white');
          filter: $pagination-shadow;
          &:hover {
            background: color('primary');
          }
        }
        &[aria-label='Previous page'] {
          &:hover {
            .prev {
              @include pagination-white-left-arrow;
            }
          }
        }
        &[aria-label='Next page'] {
          &:hover {
            .next {
              @include pagination-white-right-arrow;
            }
          }
        }
        .prev,
        .next {
          @include before-after;
          width: 14px;
          height: 9px;
          margin-top: -3px;
          background-size: contain;
        }
        .prev {
          background-image: url('../../../images/pagination-left.svg');
        }
        .next {
          background-image: url('../../../images/pagination-right.svg');
        }
      }
      &.btn-disabled {
        #{$pagination}__page-link {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .ar-common-pagination {
    &__select-page,
    &__title {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .ar-common-pagination {
    margin: 0 65px 0 0;
  }
}

@media (min-width: 1920px) {
  .ar-common-pagination {
    $pagination: '.ar-common-pagination';
    &__wrapper {
      #{$pagination}__page-item {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        #{$pagination}__page-link {
          font-size: $h5-font-size;
          .prev,
          .next {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 3500px) {
  $pagination: '.ar-common-pagination';
  .ar-common-pagination {
    &__wrapper {
      #{$pagination}__page-item {
        #{$pagination}__page-link {
          font-size: 18px;
        }
      }
    }
  }
}
