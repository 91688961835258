//sass-lint:disable function-name-format
//sass-lint:disable mixins-before-declarations
body::after {
  display: block;
  width: 0;
  height: 0;
  content: url('../../#{$edit-icon}') url('../../#{$edit-page-icon}') url('../../#{$delete-icon}')
  url('../../#{$edit-hover-icon}') url('../../#{$edit-page-hover-icon}') url('../../#{$delete-hover-icon}')
  url('../../#{$download-drop}') url('../../#{$download-hover-drop}');
  overflow: hidden;
}
@mixin button-common-styles {
  @include body-normal;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: initial;
  min-height: initial;
  margin: 0;
  border: 0;
  border-radius: 5px;
  font-family: $lota-grotesque-bold;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  vertical-align: top;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  @media (min-width: 3500px) {
    font-size: 24px;
  }
}

@mixin icon-rounded {
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 14px;
  @media (min-width: 1441px) {
    width: 40px;
    height: 40px;
    padding: 4px;
    background-size: 18px;
  }
  @media (min-width: 3500px) {
    width: 54px;
    height: 54px;
  }
}

@mixin primary-button {
  padding: 10px;
  background-color: color('primary');
  color: color('pure-white');
  letter-spacing: normal;
  opacity: 1;
  &:hover,
  &:focus,
  &:active {
    background-color: $th-primary-hover;
    color: color('pure-white');
  }
}

@mixin secondary-button {
  padding: 8px 40px;
  background-color: $th-dark-grey;
  color: color('pure-white');
  letter-spacing: normal;
  opacity: 1;
  &:hover,
  &:focus,
  &:active {
    background-color: color('pure-black');
    color: color('pure-white');
  }
  @media (min-width: 3500px) {
    padding: 12px 42px;
  }
}

@mixin ternary-button {
  padding: 8px 40px;
  border-radius: 100px;
  background-color: color('primary');
  color: color('pure-white');
  letter-spacing: normal;
  opacity: 1;
  &:hover,
  &:focus,
  &:active {
    background-color: color('pure-black');
    color: color('pure-white');
  }
  &:disabled {
    opacity: 0.8;
    pointer-events: none;
  }
  @media (min-width: 3500px) {
    padding: 12px 42px;
  }
}

@mixin light-button {
  padding: 7px 22px 5px;
  border-radius: 100px;
  background-color: $th-white-gray;
  color: color('primary');
  letter-spacing: normal;
  opacity: 1;
  &:hover,
  &:focus,
  &:active {
    background-color: color('pure-black');
    color: color('pure-white');
  }
  @media (min-width: 3500px) {
    padding: 10px 40px;
  }
}

@mixin light-button-outline {
  padding: 7px 22px 5px;
  border: 1px solid var(--th-white-invet);
  border-radius: 100px;
  background-color: transparent;
  color: var(--th-white-invet);
  font-family: $lota-grotesque-regular;
  letter-spacing: normal;
  &:hover,
  &:focus,
  &:active {
    border-color: color('primary');
    background-color: color('pure-white');
    color: color('primary');
  }
  @media (min-width: 3500px) {
    padding: 12px 42px;
  }
}

@mixin primary-button-icon($edit: true) {
  position: relative;
  padding: 10px 10px 10px 27px;
  color: $th-light-blue;
  font-family: $lota-grotesque-regular;
  font-size: 12px;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
  &::before {
    top: 50%;
    @include before-after;
    @if $edit {
      @include edit-icon-dark;
      background-position: $th-icon-edit;
    } @else {
      @include delete-icon-dark;
      background-position: $th-icon-delete;
    }
    left: 0;
    transform: translateY(-50%);
    filter: none;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $th-tertiary-color-alt;
    &::before {
      filter: $th-brightness;
    }
  }
}
.ar-btn {
  @include button-common-styles;
  &.ar-btn--primary {
    @include primary-button;
    white-space: nowrap;
    &.ar-btn--icon-rounded {
      @include icon-rounded;
    }
    &--revision {
      background-color: $revision-color;
      background-image: url('../../#{$icon-revision-white}');
    }
    &--comment {
      background-color: $comment-bg;
      background-image: url('../../#{$icon-comment-white}');
      background-size: 19px;
      &:hover {
        background-color: $comment-bg;
        opacity: 0.8;
      }
      &.count {
        background-image: none;
        font-family: $lota-grotesque-regular;
        font-size: $h3-font-size;
        font-weight: $font-weight-light;
        line-height: 1;
      }
    }
    &-fg-down {
      width: 30px;
      height: 30px;
      border: 2px solid $component-text-color;
      border-radius: 50%;
      .svg-inline--fa {
        color: $component-text-color;
        font-size: $h3-font-size;
      }
      &:hover {
        border: 2px solid color('primary-light');
        .svg-inline--fa {
          color: color('primary-light');
        }
      }
    }
  }
  &.ar-btn--light {
    @include light-button;
    &.ar-btn--icon-rounded {
      @include icon-rounded;
    }
  }
  &.ar-btn-outline--light {
    @include light-button-outline;
    &.ar-btn--icon-rounded {
      @include icon-rounded;
    }
  }
  &.ar-btn--secondary {
    @include secondary-button;
    &.ar-btn--icon-rounded {
      @include icon-rounded;
    }
    &--duplicate {
      background-image: url('../../#{$icon-duplicate-white}');
    }
  }
  &.ar-btn--ternary {
    @include ternary-button;
    &.ar-btn--icon-rounded {
      @include icon-rounded;
    }
  }
  &.ar-btn--primary-edit,
  &.ar-btn--primary-page-edit,
  &.ar-btn--primary-delete {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 29px;
  }
  &.ar-btn--primary-edit {
    background-image: url('../../#{$edit-icon}');
    &:hover {
      background-image: url('../../#{$edit-hover-icon}');
    }
  }
  &.ar-btn--primary-page-edit {
    background-image: url('../../#{$edit-page-icon}');
    &:hover {
      background-image: url('../../#{$edit-page-hover-icon}');
    }
  }
  &.ar-btn--primary-delete {
    background-image: url('../../#{$delete-icon}');
    &:hover {
      filter: invert(25%) sepia(67%) saturate(4650%) hue-rotate(320deg) brightness(95%) contrast(200%);
    }
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &--exit-comment-mode {
    position: absolute;
    right: -125px;
    padding: 6px 10px 4px;
    border-radius: 13px;
    background: $danger-bg;
    color: color('pure-white');
    font-size: 12px;
    font-weight: $font-weight-normal;
  }
}
