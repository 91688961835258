.ar-preview-img {
  position: relative;
  label {
    position: relative;
    z-index: 3;
  }
  &-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 5px 5px 0 0;
    background: $preview-img-bg;
    text-align: center;
    z-index: 1;
    img {
      height: 115px;
      border-radius: 5px 5px 0 0;
      object-fit: contain;
    }
    &::after {
      position: absolute;
      right: 0;
      bottom: -30px;
      left: 0;
      width: 100%;
      height: 30px;
      border-radius: 0 0 5px 5px;
      background: $preview-img-info-bg;
      content: '';
      z-index: 2;
    }
    ~ label {
      flex-direction: column;
      height: 110px;
      margin-bottom: 45px;
      padding-top: 15px;
      border: 0;
      svg {
        display: none;
      }
      div {
        position: absolute;
        bottom: -30px;

        flex-direction: row !important;// sass-lint:disable-line no-important
        width: 100%;
        max-height: 30px;
        padding: 0 15px;
        span {
          color: color('pure-white');
        }
      }
    }
  }
}
