.ar-common-status-dropdown {
  &-wrapper {
    &.header-top {
      position: fixed;
      top: 16px;
      right: 415px;
      min-width: 185px;
      z-index: $z-index-top;
    }
  }

  .inner-dropdown {
    display: flex;
    align-items: center;
    color: var(--th-status-text);
    font-size: $h5-font-size;

    .shape-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      margin-right: 7px;
      border: 1px solid rgba(color('pure-white'), 0.2);
      border-radius: 50%;
      span {
        &.shape {
          display: inline-block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          &.in-design {
            background: $in-design;
          }
          &.in-verification {
            background: $in-verification;
          }
          &.approved {
            background: $approved;
          }
          &.moodgen-ready {
            background: $moodgen-ready;
          }
        }
      }
    }
  }

  [class$='-ValueContainer'] {
    height: 100%;
    padding: 0 8px;
  }

  [class$='-indicatorContainer'] {
    padding: 4px 8px;
  }

  [class$='-control'] {
    height: 32px;
    min-height: 32px;
    border: 0;
    border-radius: 19px;
    background: var(--th-element-bg);
    box-shadow: none;
  }

  [class$='-MenuList'] {
    padding: 0;
    border-radius: 8px;
    background: var(--th-element-bg);
  }

  [class$='-option'],
  [class$='-option']:active {
    background: none;
  }

  [class$='-option']:hover {
    background: var(--th-status-hover);
  }

  [class$='-menu'] {
    border-radius: 8px;
    background: var(--th-element-bg);
  }

  // sass-lint:disable class-name-format
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  // sass-lint:enable class-name-format

  .rctslct-status {
    &__option--is-selected {
      .inner-dropdown {
        .shape-wrapper {
          border-color: var(--th-status-text);
        }
      }
    }

    &__option--is-focused {
      background: none;
    }
  }
  &-list-pages {
    display: inline-block;
    position: absolute;
    left: 80px;
    min-width: 165px;
    height: 28px;
    margin: 0 10px 8px 6px;
    padding: 4px 25px 4px 8px;
    border-radius: 19px;
    background: var(--th-element-bg);
  }
}

@media (min-width: 1441px) {
  .ar-common-status-dropdown {
    &-wrapper {
      &.header-top {
        right: 478px;
      }
    }
    .inner-dropdown {
      .shape-wrapper {
        width: 26px;
        height: 26px;
        span {
          &.shape {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    [class$='-indicatorContainer'] {
      padding: 8px;
    }
    [class$='-control'] {
      height: auto;
      min-height: 38px;
    }
    &-list-pages {
      left: 105px;
      height: 38px;
      padding: 6px 25px 6px 8px;
    }
  }
}
