.forbidden-page {
  margin: 50px 0;
  text-align: center;
  p {
    color: var(--th-breadcrumb-color);
    a:hover {
      color: color('primary');
    }
  }
}
