.prototype {
  &__remove {
    margin: 0 0 20px;
  }
}

@include media-breakpoint-up(md) {
  .prototype {
    &__remove {
      margin: 0 0 8px;
    }
    button {
      &[type='button'] {
        margin: 0 5px 0 0;
        float: left;
      }
    }
  }
}
