// sass-lint:disable no-misspelled-properties

@mixin overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
  background-color: var(--th-ref-overlay);
  opacity: 0;
  &__checkbok {
    position: relative;
    top: 40%;
    width: 100%;
    padding: 14px 0 0 24px;
    color: color('pure-white');
  }
  &__buttons {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    button {
      @include primary-button;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 2px;
      padding: 2px;
      border: 0;
      border-radius: 50px;
      color: color('pure-white');
      @media (min-width: 3500px) {
        width: 75px;
        height: 75px;
        font-size: 26px;
      }
      &:focus {
        box-shadow: unset;
      }
    }
  }
  &:hover {
    opacity: 1;
  }
}

@mixin dissable-move {
  $ar-edit-page: '.ar-edit-page';
  #{$ar-edit-page}__wrapper {
    &:first-child {
      #{$ar-edit-page}__actions__move {
        .ar-btn {
          &:first-child {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
    &:last-child {
      #{$ar-edit-page}__actions__move {
        .ar-btn {
          &:nth-child(2) {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.ar-edit-page,
.ar-common-popup__prv {
  > div {
    @include dissable-move;
  }
}

.ar-edit-page {
  max-width: 630px;
  &__body {
    margin-left: 30px;
    border: 1px solid $th-light-border;
  }
  &__add-after {
    display: flex;
    position: absolute;
    align-items: flex-end;
    transform: scale(0.5);
    transition: transform ease-in-out 0.15s, opacity ease-in 0.1s, background-image ease-in 0.1s;
    background: url('../#{$gradient-stripe}') no-repeat center center / contain;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    inset: auto 0 -18px 0;
    button {
      position: relative;
      margin: 0 auto;
      &.ar-btn {
        width: 35px;
        height: 35px;
        padding: 2px;
      }
    }
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
  &__wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    &[draggable='false'] {
      // sass-lint:disable no-important
      transform: none !important; // added to override plugin inline style
    }
    .badge {
      background-color: color('primary-light');
      z-index: 1;
      &.revision-badge {
        position: absolute;
        top: 50%;
        right: auto;
        left: 50%;
        padding: 5px 20px 4px;
        transform: translate(-50%, -50%);
        background: color('primary');
        font-family: $lota-grotesque-light;
        font-size: 12px;
        font-weight: $font-weight-light;
        text-transform: none;
        cursor: default;
      }
    }
    img {
      &.cover {
        cursor: crosshair;
      }
    }
  }
  &__badges {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    .badge {
      display: inline-block;
      margin-right: 6px;
      padding: 3px 7px 2px;
    }
  }
  &__checkbox-wrapper {
    text-align: right;
    .ar-common-checkbox {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      z-index: 2;
      input,
      span {
        cursor: pointer;
      }
      span {
        border-width: 2px;
        border-color: color('secondary');
      }
      input {
        &[type='checkbox'] {
          right: 0;
          &:checked + span {
            background: color('primary');
            &::after {
              transform: rotate(45deg);
              border: solid color('pure-white');
              border-width: 0 3px 3px 0;
              background: none;
            }
          }
        }
      }
    }
    .ar-edit-page__details {
      text-align: left;
      grid-template-columns: 75% 25%;
    }
  }

  &__single-sort-wrapper {
    position: relative;
  }

  &__single-sort-wrapper:not(:first-child) {
    border-top: 1px solid #d6d6d6;
  }

  &__details {
    display: grid;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3px 10px;
    background-color: rgba(color('pure-white'), 0.8);
    opacity: 0;
    z-index: 2;
    grid-gap: 14%;
    grid-template-columns: 43% 43%;
    grid-template-rows: 100%;
    > * {
      padding: 0 5px;
      font-family: $bentonsans-medium;
      text-transform: uppercase;
    }
    button {
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__wrapper:not(.ar-edit-page__options):hover,
  &__checkbox-wrapper:not(.ar-edit-page__options):hover {
    .ar-edit-page__details {
      transition: opacity 0.8s;
      opacity: 1;
    }
    .ar-edit-page__add-after {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
  }
  &__image {
    position: relative;
    width: 50%;
  }
  &__image:hover {
    .ar-edit-page__details {
      transition: opacity 0.8s;
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }

  &__close {
    display: flex;
    position: absolute;
    top: 0;
    left: calc(100% + 20px);
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 6px 0 0;
    border: $border-3;
    border-radius: 50%;
    color: color('primary');
    cursor: pointer;
    &:hover {
      background-color: color('primary');
      color: color('pure-white');
    }
  }

  &__add-section {
    margin: 20px 0 0 30px;
    padding: 20px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    border: $border-4;
    color: color('primary');
    font-size: $h2-font-size;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      background-color: color('primary');
      color: color('pure-white');
    }
  }

  &__add-options {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding: 0 22px;
    &::before {
      display: block;
      width: 100%;
      height: 3px;
      background-color: var(--th-element-bg);
      content: '';
    }
    button {
      width: 30px;
      height: 30px;
      margin-left: 8px;
      padding: 4px;
      border-radius: 50%;
      &.btn-secondary {
        border-color: var(--th-element-bg);
        background-color: var(--th-element-bg);
        color: var(--th-white-invet);
      }
    }
  }

  &__btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;
    gap: 0 20px;
  }

  &__project,
  &__page {
    display: flex;
    flex-direction: row;
  }

  &__project {
    align-items: center;
    span {
      @include body-normal;
      display: inline-block;
      margin: 0 10px 4px;
      color: var(--th-secondary-light);
      &:first-child {
        color: color('primary');
    }
    }
  }

  &__page {
    font-size: 12px;
    line-height: 1.2;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    &__move {
      display: flex;
    }
    button {
      &:not(:first-child) {
        margin-left: 5px;
      }
      &.ar-btn,
      &.btn {
        width: 28px;
        height: 28px;
        padding: 2px;
        font-size: 14px;
      }
      &.ar-btn--light-drag {
        background: url('../#{$drag-icon}') no-repeat center center;
      }
    }
  }
  &__moodboard-options,
  &__moodboard-popup {
    @include dropdown-box-shadow;
    .options-overlay,
    .modal-body {
      .card {
        margin-bottom: 15px;
        img {
          width: 100%;
          height: 156px;
          object-fit: scale-down;
          &:hover {
            cursor: pointer;
          }
        }
        .overlay {
          @include overlay;
        }
        .row {
          min-width: 600px;
          text-align: center;
          .col {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 156px;
          }
        }
      }
    }
    .selected-image {
      padding: 0;
      > div,
      .image-container,
      img {
        height: 100%;
      }
      .image-container {
        width: 100%;
        background-color: var(--th-text-white);
        img {
          height: 196px;
          padding: 16px;
        }
      }
    }
    .btn-plus {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      border-color: color('primary');
      background-color: color('primary');
      color: color('pure-white');
      line-height: 1.72;
      &:hover,
      &:focus,
      &:active {
        // sass-lint:disable no-important
        background-color: color('primary-dark') !important;
        color: color('pure-white');
      }
    }

    .btn {
      &.btn-plus {
        &.btn {
          &.btn-primary {
            &:focus,
            &.focus,
            &:active,
            &.active {
              box-shadow: $select-box-shadow;
            }
          }
        }
      }
    }

    .ar-common-pagination__title {
      display: none;
    }
    &-prv {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      padding: 50px;
      z-index: 1;
      .overlay {
        @include overlay;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      &__selected {
        width: 100%;
        .row {
          justify-content: center;
        }
      }
    }
    .rctslct__multi-value__remove {
      color: color('pure-black');
    }
  }

  &__divider {
    padding: 0;
  }

  &__moodboard-popup-title * {
    font-family: $opensans-medium;
    font-size: $h2-font-size;
  }

  &__moodboard-popup-title {
    input {
      background-color: var(--th-element-bg-light);
      &.search {
        @include body-normal;
        padding: 23px 20px;
        border: 0;
        border-radius: 100px;
        color: color('th-secondary-color-alt');
        &:focus {
          background-color: var(--th-element-bg-light);
        }
      }
    }
  }

  &__moodboard-option-title,
  &__moodboard-gallery-title {
    font-family: $opensans-medium;
  }

  &__moodboard-gallery-selections {
    p {
      margin-left: -15px;
    }
    &__item {
      .reference-added {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.42);
        color: $th-text-white;
        z-index: 1;
      }
    }
  }
  &__moodboard-popup-body {
    .reference-screen-close {
      position: absolute;
      top: 0;
      right: 5px;
    }
  }
  &__filter-panel {
    display: flex;
    margin-top: 20px;
    gap: 10px;
  }
  &__filter-panel-column {
    width: 50%;

    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
    label {
      font-size: medium;
    }
  }

  & &__options {
    margin-bottom: 50px;
  }

  &__moodboard-options {
    width: 50%;
    .rctslct {
      &__control {
        border: 0;
        border-radius: 22px;
        background-color: var(--th-element-bg-light);
        .rctslct__indicator {
          color: $breadcrumb-color;
          &-separator {
            opacity: 0.2;
          }
        }
      }
      &__multi-value {
        border-radius: 5px;
        color: var(--th-white-invet);
      }
    }
    > div {
      .card {
        &:not(:first-child) {
          margin-top: 26px;
        }
      }
    }
    .card {
      border: 0;
      .btn-remove {
        width: 30px;
        height: 30px;
        margin-left: 8px;
        padding: 4px;
        border-radius: 50%;
        &.btn-secondary {
          border-color: var(--th-element-bg);
          background-color: var(--th-element-bg);
        }
      }
      img {
        max-width: 100%;
        height: 196px;
        object-fit: scale-down;
      }
      .overlay {
        @include overlay;
      }
      .col {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 196px;
      }
    }
    .card-title {
      margin-bottom: 0;
    }
    &-body {
      margin-top: 16px;
    }
    &-title {
      font-size: 18px;
      white-space: nowrap;
    }
    &-keywords {
      width: calc(100% - 124px);
      margin: 0 8px 0 0;
    }
    .ar-btn--primary-trash {
      position: relative;
      width: 31px;
      height: 31px;
      border-radius: 50px;
      background-color: var(--th-element-bg);
      &::after {
        @include before-after;
        background: url('../#{$icon-trash}') no-repeat center center / 14px;
        opacity: var(--th-opacity);
        filter: var(--th-invert-icon);
        inset: 0;
      }
    }
  }
  &-cursor-tooltip {
    display: inline-block;
    position: absolute;
    padding: 4px 7px;
    border-radius: 7px;
    background: color('pure-black');
    color: color('pure-white');
    font-size: 7px;
    line-height: 1;
    z-index: 1000;
  }
}

@media (min-width: 1441px) {
  .ar-edit-page {
    max-width: 880px;
    &__actions {
      button {
        &.ar-btn,
        &.btn {
          width: 40px;
          height: 40px;
          font-size: 19px;
        }
      }
    }
    &__project,
    &__page {
      flex-direction: column;
    }
    &__project {
      align-items: flex-start;
      justify-content: center;
      max-width: 86%;
    }
    &__wrapper {
      .badge {
        &.revision-badge {
          padding: 10px 25px;
          font-size: $h5-font-size;
        }
      }
    }
    &__details {
      grid-gap: 12%;
      grid-template-columns: 44% 44%;
    }
  }
}

@media (min-width: 3500px) {
  .ar-edit-page {
    max-width: 1400px;
    &__moodboard-options-prv,
    &__moodboard-popup-prv {
      width: 47%;
    }
    &__moodboard-options,
    &__moodboard-popup {
      .options-overlay,
      .modal-body {
        .card {
          img {
            height: 392px;
          }
        }
      }
      .selected-image {
        .image-container {
          img {
            height: 392px;
          }
        }
      }
    }
    &__moodboard-options {
      .card {
        .col {
          height: 397px;
        }
      }
      .options-overlay {
        .card {
          img {
            height: 392px;
          }
        }
      }
      .btn-plus {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        font-size: 40px;
      }
    }
    &__moodboard-popup {
      .btn-plus {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        font-size: 40px;
      }
      .modal-body {
        .card {
          .row {
            .col {
              min-height: 392px;
            }
          }
        }
      }
    }
    &__moodboard-popup-title {
      input {
        &.search {
          font-size: 20px;
        }
      }
    }
    &__add-after {
      button {
        .ar-btn {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
