.ar-revision-popup {
  padding: 15px 0 0 15px;
  &-image {
    margin-bottom: 31px;
    .ar-revision-popup {
      &-sub-heading {
        font-size: 18px;
        font-weight: $font-weight-light;
        &-data {
          padding-left: 20px;
        }
      }
    }
    &-action {
      display: flex;
      position: absolute;
      bottom: 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      border-radius: 0 0 5px 5px;
      background: rgba(60, 60, 78, 0.8);
      p {
        @include title-two;
        margin: 0;
        color: color('pure-white');
        font-family: $lota-grotesque-regular;
      }
      .ar-btn {
        padding: 6px 18px 4px;
        border-color: color('pure-white');
        color: color('pure-white');
      }
    }
  }

  &-add-files {
    .ar-revision-popup {
      &-sub-heading {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
    .form-group {
      .btn-sm {
        margin-right: 0 !important; // sass-lint:disable-line no-important
      }
    }
  }

  &-sub-heading {
    margin-bottom: 8px;
    color: $th-primary-color-alt;
  }

  &-image-wrapper {
    display: inline-block;
    position: relative;
    img {
      width: 640px;
      height: 340px;
      border-radius: 5px;
      background: $post-bg-color;
      object-fit: contain;
    }
  }

  .form-group {
    label {
      color: $th-primary-color-alt;
      &[for='file'] {
        flex-direction: column;
        height: 110px;
        padding-top: 15px;
        div {
          flex-direction: column;
          align-items: center;
          margin-top: 6px;
        }
      }
    }
  }
}
