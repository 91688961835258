.ar-comment-box {
  display: none;
  position: absolute;
  min-width: 245px;
  margin-top: 22px;
  padding: 6px 10px 5px;
  border-radius: 3px;
  background: color('pure-white');
  box-shadow: 0 4px 10px rgba(color('pure-black'), 0.2);
  z-index: 10;
  form {
    .ar-common-form__group {
      label {
        color: var(--th-secondary-light);
        font-size: 10px;
      }
    }
    .text-area {
      margin-bottom: 6px;
      &.form-control {
        padding: 4px 5px;
        border: 1px solid $component-text-color;
        border-radius: 3px;
      }
      &::placeholder {
        color: $comment-placeholder-color;
        font-size: 8px;
        font-weight: $font-weight-light;
        line-height: 1.5;
      }
    }
  }
  .ar-btn {
    margin-left: auto;
    padding: 4px 10px 2px;
    font-size: 9px;
  }
  &-close {
    position: absolute;
    top: 9px;
    right: 6px;
    width: 9px;
    height: 9px;
    background: url('../#{$icon-comment-close}') no-repeat center center;
    background-size: contain;
    cursor: pointer;
    z-index: 10;
  }
  &-label {
    display: flex;
    position: absolute;
    top: -22px;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: color('primary');
    font-size: 10px;
    cursor: pointer;
  }
  &-commented {
    max-width: 225px;
    padding: 5px 0;
    &-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    &-profile {
      display: flex;
      align-items: center;
    }
    &-profile-image {
      margin-right: 5px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    &-profile-name {
      color: var(--th-secondary-light);
      font-size: 8px;
    }
    &-profile-time {
      color: $comment-placeholder-color;
      font-size: 6px;
      font-weight: $font-weight-light;
    }
    &-content {
      p {
        margin-bottom: 4px;
        color: $comment-placeholder-color;
        font-size: 9px;
        font-weight: $font-weight-light;
        line-height: 1.4;
      }
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    .ar-btn {
      width: 22px;
      height: 22px;
      background-size: 21px;
      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
