.ar-common-content {
  &__inner,
  &--view-pages {
    padding: 0 30px;
  }
  &__inner {
    &-prototypes {
      .ar-common-post__title {
        margin-bottom: 20px;
      }
    }
  }
  &__select-reference {
    padding: 0;
    .ar-common-posts__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
    .ar-common-post__checkbox-wrapper {
      right: 18px;
    }
  }
  &-alert-msg {
    padding: 0 30px;
    a {
      color: color('primary');
      text-decoration: none;
      &:hover {
        color: color('primary-light');
      }
    }
    .alert {
      display: flex;
      align-items: center;
    }
    &-icon {
      margin-right: 12px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
