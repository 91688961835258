// sass-lint:disable no-vendor-prefixes
@mixin title-one {
  margin-bottom: 10px;
  color: $th-primary-color-alt;
  font-family: $lota-grotesque-bold;
  font-size: 36px;
  letter-spacing: -0.5px;
  line-height: 42px;
}

@mixin title-two {
  color: $th-primary-color-alt;
  font-family: $lota-grotesque-bold;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 22px;
}

@mixin title-three {
  color: $th-primary-color-alt;
  font-family: $lota-grotesque-bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

@mixin body-normal {
  font-family: $lota-grotesque-regular;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  @media (min-width: 1441px) {
    font-size: 16px;
  }
  @media (min-width: 1920px) {
    font-size: 18px;
  }
  @media (min-width: 3500px) {
    font-size: 30px;
  }
}

@mixin before-after {
  display: block;
  position: absolute;
  content: '';
}

@mixin common-img($objfit: contain) {
  width: 100%;
  height: 100%;
  object-fit: $objfit;
}

@mixin transition($type: all, $duration: 0.3s) {
  display: block;
  transition: $type $duration ease-in-out;
  content: '';
}

@mixin line-clamp($numberline: 3) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $numberline;
  -webkit-box-orient: vertical;
}

@mixin dropdown-box-shadow {
  .css-1pahdxg-control,
  .form-control:focus {
    box-shadow: $select-box-shadow;
  }
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    border-color: transparent;
  }
  .rctslct {
    &__option--is-selected ,
    &__option--is-focused,
    &__option:hover {
      color: $th-text-white !important; // sass-lint:disable-line no-important
    }
    &__option--is-selected,
    &__option:active {
      background-color: color('primary');
    }
    &__option:hover {
      background-color: $drop-down-hover;
    }
    &__option {
      &.css-1n7v3ny-option {
        background-color: $drop-down-hover;
      }
    }
    &__option:not(.rctslct__option--is-focused) {
      color: color('pure-black');
    }
  }
}

@mixin plus-icon-image {
  @include before-after;
  top: 50%;
  left: 15px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  background-image: url('../../../../images/Plus_Icon.svg');
  background-size: contain;
}
