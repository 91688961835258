// sass-lint:disable no-misspelled-properties
.ar-components-details-popup {
  $ar-components-details: '.ar-components-details';
  @mixin common-sub-titles {
    p,
    span {
      display: inline-block;
    }
    p {
      margin: 0;
      color: var(--th-breadcrumb-color);
      font-size: $h3-font-size;
    }
    span {
      color: $breadcrumb-color;
      font-size: $h3-font-size;
      font-weight: $font-weight-light;
    }
  }
  &-sub-heading {
    margin: 40px 0 25px;
    color: var(--th-primary-color-alt);
    font-family: $opensans-medium;
    font-size: $h2-font-size;
  }
  &-main-details {
    @include common-sub-titles;
    display: flex;
    align-items: center;
    width: 97%;
    min-height: 44px;
    margin-bottom: 20px;
    padding: 12px 22px;
    border: 1px solid var(--th-section-border);
    border-radius: $border-radius-5;
    background: var(--th-bg-color);
    &-item-type {
      width: 68%;
    }
    &-item-project {
      width: 32%;
    }
  }
  .modal-body {
    padding: 20px 0 20px 30px;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 30px;
  }
  &-slider {
    .slick-slider {
      &:hover {
        .slick-prev,
        .slick-next {
          opacity: 0.7;
          visibility: visible;
        }
      }
      .slick-prev,
      .slick-next {
        &:hover {
          opacity: 1;
        }
      }
    }
    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      border-radius: 50%;
      background: $slider-arrow-bg;
      opacity: 0;
      visibility: hidden;
      z-index: 10;
      &::before {
        display: block;
        width: 19px;
        height: 12px;
        margin: 0 auto;
        background-size: contain;
        content: '';
        opacity: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
    .slick-prev {
      left: 15px;
      &::before {
        background-image: url('../../../images/slider-left.svg');
      }
    }
    .slick-next {
      right: 15px;
      &::before {
        background-image: url('../../../images/slider-right.svg');
      }
    }
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $preview-img-bg;
    }
    &-main {
      position: relative;
      width: 68%;
      .slick-slide {
        height: 380px;
      }
      .slick-list {
        position: relative;
        border-radius: $border-radius-5;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
          border-radius: $border-radius-5;
          background: rgba(color('pure-white'), 0.5);
          content: '';
          opacity: 0;
          visibility: hidden;
          z-index: 2;
        }
        &:hover {
          &::after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      &:hover {
        #{$ar-components-details}-popup-slider-actions {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &-actions {
      display: flex;
      position: absolute;
      right: 6px;
      bottom: 10px;
      align-items: center;
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      z-index: 8;
      .sketch,
      .figma {
        &::before {
          @include before-after;
          top: 8px;
          right: 12px;
          width: 10px;
          height: 12px;
          background-image: url('../../../images/down-img.svg');
          background-size: contain;
        }
      }
      .ar-btn {
        margin-right: 10px;
        padding: 6px 28px 5px 11px;
        background: $slider-btn-bg;
        color: color('pure-white');
        font-family: $lota-grotesque-regular;
        font-size: $h5-font-size;
        font-weight: $font-weight-normal;
      }
    }
  }
  &-other-details {
    width: 32%;
    padding-left: 20px;
    #{$ar-components-details}-popup-badges {
      display: flex;
      align-items: center;
      .badge {
        display: flex;
        align-items: center;
        min-height: 21px;
        padding: 2px 12px;
        font-weight: $font-weight-light;
        &:first-child {
          margin-right: 10px;
        }
        &.realm-badge {
          padding: 2px 20px;
          background: $details-realm-badge;
        }
        &.by-reference {
          background: $in-design;
        }
      }
    }
    #{$ar-components-details}-popup-details {
      margin: 20px 0;
      padding: 10px 11px;
      border: 1px solid var(--th-section-border);
      border-radius: $border-radius-5;
      background: var(--th-bg-color);
      &-item {
        @include common-sub-titles;
        margin: 0 0 12px;
        line-height: 1.3;
        &:last-child {
          margin: 0;
        }
        p {
          display: block;
          line-height: 1;
        }
        span {
          font-size: 12px;
          line-height: 0.8;
        }
      }
    }
    #{$ar-components-details}-popup-usage-count {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 15px 18px;
      border: 1px solid var(--th-section-border);
      border-radius: $border-radius-5;
      background: var(--th-bg-color);
      p,
      span {
        margin: 0;
        color: var(--th-breadcrumb-color);
        line-height: 1;
      }
      p {
        margin-bottom: 4px;
        font-size: 50px;
      }
      span {
        font-size: $h3-font-size;
      }
    }
  }
  &-status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 20px;
    row-gap: 15px;
    &-item {
      display: flex;
      position: relative;
      align-items: center;
      transition: opacity 0.3s ease-in-out;
      gap: 4px;
      &:hover {
        text-decoration: none;
        opacity: 0.8;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        color: var(--th-breadcrumb-color);
        font-family: $lota-grotesque-regular;
        line-height: 1;
        &::after {
          display: inline-block;
          position: relative;
          top: 2px;
          width: 15px;
          height: 12px;
          margin-left: 6px;
          background: url('../../../images/open-link.svg')no-repeat center center;
          background-size: contain;
          content: '';
          filter: var(--th-invert-icon);
        }
      }
      &::before,
      &::after {
        display: inline-block;
        content: '';
      }
      &::before {
        width: 30px;
        height: 30px;
        border: 1px solid var(--th-secondary-color-alt);
        border-radius: 50%;
      }
      &::after {
        position: absolute;
        left: 9px;
        width: 14px;
        height: 10px;
      }
      &.available {
        cursor: pointer;
        &::before {
          border-color: $download-color;
          background: $download-color;
        }
        &::after {
          background: url('../../../images/available.svg')no-repeat center center;
          background-size: contain;
        }
      }
    }
    &-wrapper {
      padding-right: 30px;
    }
  }
  &-currently-usedin {
    &-usage-count {
      p {
        margin-bottom: 8px;
        color: $breadcrumb-color;
        font-family: $lota-grotesque-regular;
        font-size: $h3-font-size;
      }
    }
    #{$ar-components-details}-popup-sub-heading {
      margin-bottom: 8px;
    }
    &-list {
      overflow-x: auto;
      .ar-common-post {
        min-width: 300px;
      }
      &-empty {
        width: 100%;
        margin-top: 55px;
        padding: 36px 30px 30px;
        border: 1px solid var(--th-section-border);
        border-radius: 5px;
        background: var(--th-bg-color);
        text-align: center;
        p,
        span {
          color: var(--th-breadcrumb-color);
        }
        p {
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: $font-weight-normal;
        }
        span {
          font-size: $h3-font-size;
          font-weight: $font-weight-light;
        }
      }
    }
    &-list-inner {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
      .ar-common-post {
        margin-top: 15px;
      }
    }
    &.no-view-more {
      padding-right: 20px;
    }
  }
  &-view-more {
    span {
      display: flex;
      align-items: center;
      min-width: 130px;
      padding: 0 30px 0 0;
      transition: opacity 0.3s ease-in-out;
      color: var(--th-breadcrumb-color);
      font-size: $h5-font-size;
      cursor: pointer;
      &::after {
        display: inline-block;
        width: 19px;
        height: 12px;
        margin-left: 6px;
        background: url('../../../images/view-more.svg')no-repeat center center;
        background-size: contain;
        content: '';
        filter: var(--th-invert-icon);
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-item-modal {
    &.modal-dialog {
      position: absolute;
      top: 0;
      left: 0;
      width: 49%;
      max-width: 100%;
      height: 100%;
      margin: 0;
      .modal-content {
        height: 100%;
        background-color: var(--th-primary-color);
        .modal-header {
          border-bottom: 1px solid var(--th-border-dark);
          color: var(--th-primary-color-alt);
        }
        .modal-body {
          height: 100vh;
          padding: 20px 30px;
          overflow: auto;
        }
        .modal-footer {
          border-top: 1px solid var(--th-border-dark);
        }
      }
    }
  }
}

@media (min-width: 1620px) {
  .ar-components-details-popup {
    &-slider {
      &-main {
        width: 72%;
      }
    }
    &-other-details {
      width: 28%;
      padding-left: 30px;
    }
    &-status {
      column-gap: 45px;
      &-item {
        span {
          margin-left: 15px;
        }
        &::before {
          width: 38px;
          height: 38px;
        }
        &::after {
          width: 19px;
          height: 14px;
        }
      }
    }
  }
}

.modal-backdrop {
  &.show {
    opacity: 0.5;
  }
}
